.App {
	height: 100%;
	display: flex;
	flex-direction: column;
}

.App-logo {
	height: 40vmin;
	pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
	.App-logo {
		animation: App-logo-spin infinite 20s linear;
	}
}

.App-header {
	background-color: #282c34;
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	font-size: calc(10px + 2vmin);
	color: white;
}

.App-link {
	color: #61dafb;
}

@keyframes App-logo-spin {
	from {
		transform: rotate(0deg);
	}
	to {
		transform: rotate(360deg);
	}
}

.my-title {
	color: black;
}

amplify-authenticator {
	--container-justify: center;
	--border-radius: 3px;
	--container-align: center;
	--container-height: none;
	overflow: auto;
}

amplify-sign-in {
	display: flex;
	--container-justify: center;
	--border-radius: 3px;
	--container-align: center;
}

amplify-sign-up {
	overflow: auto;
}

.fc-button {
	background-color: white !important;
	border: solid 1px #4d567f;
	color: rgba(2, 9, 39, 0.87) !important;
	font-weight: 400;
	font-size: 13px;
}

.fc-day-today {
	background-color: #f2f2f4 !important;
}
