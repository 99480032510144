html {
	height: 100%;
}
body {
	margin: 0;
	font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
		"Helvetica Neue", sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	height: 100%;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

.my-title {
	padding-left: 0.55em;
	color: #1ab394;
	padding-top: 1em;
	padding-bottom: 0.9375em;
}

#root {
	height: 100%;
}

:root {
	--amplify-primary-color: #0fc3c5;
	--amplify-primary-tint: #0fc3c5;
}
